.projectLabel{
    @media screen and (max-width: 1025px) {
        flex-wrap: wrap;
    }
    &__item{
        margin-right: 20px;
        &:last-child{
            margin-right: 0;
        }
        &__square{
            &__children{
                transform: translate(-50%,-50%);
            }
        }
        @media screen and (max-width: 1025px) {
            width: 100%;
            margin-right: 0;
        }
    }
}
.LineChart,
.LineChart canvas{
    // width: 100% !important;
   //  height: auto !important;
    // height:301px !important;
    // @media screen and (max-width:1921px) {
    //     height: 371px !important;
    // }
    // @media screen and (max-width:1681px) {
    //     height: 311px !important;
    // }
    // @media screen and (max-width:1601px) {
    //     height: 291px !important;
    // }
    // @media screen and (max-width:1561px) {
    //     height: 281px !important;
    // }
    // @media screen and (max-width:1441px) {
    //     height: 251px !important;
    // }
    // @media screen and (max-width:1281px) {
    //     height: 228px !important;
    // }
    // @media screen and (max-width:1025px) {
    //    // height: 179px !important;
    // }
    // @media screen and (max-width:999px) {
    //     height: 393px !important;
    // }
    // @media screen and (max-width:641px) {
    //     height: 296px !important;
    // }
    // @media screen and (max-width:571px) {
    //     height: 261px !important;
    // }
    // @media screen and (max-width:481px) {
    //     height: 216px !important;
    // }
    // @media screen and (max-width:426px) {
    //     height: 188px !important;
    // }
    // @media screen and (max-width:400px) {
    //     height: 172px !important;
    // }
    // @media screen and (max-width:376px) {
    //     height: 163px !important;
    // }
    // @media screen and (max-width:321px) {
    //     height: 136px !important;
    // }
}
.nextMonth:hover,
.prevMonth:hover{
    svg path{
        fill: white;
    }
}