.singleDropdown {
    width: 100%;
  }
  .projectTable{
    td> div > button{
      height:100% !important;
    }
    td[id*="projectCode"],
    td[id*="projectCompleted"]
    {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  
  .customCloseIcon{
    &:hover{
        svg path{
            stroke: #EE436D;
        }
    }
  }