.custom_scrollbar{
    &::-webkit-scrollbar {
        width: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #3699FF;
        border-radius: 3px;
    
      }
    
      &.scroll-wrapper {
        max-height: 100px;
      }
}