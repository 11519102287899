.otListTable {

    th[id*="lasted"],
    td[id*="lasted"] {
        min-width: 140px !important;
        width: 140px;

        >div {
            height: 100%;
        }
    }

    th[id*="actions"],
    td[id*="actions"] {
        min-width: 140px !important;
        width: 140px;
        position: absolute !important;
        right: 0;
        left: unset !important;
        background-color: #fff;
        z-index: 1;
    }
}

.verticalLine {
    display: flex;

    &:after {
        width: 2px;
        height: 100%;
        background-color: #F5F5F5;
        display: block;
        content: '';
        margin-left: 16px;
    }
}

.otCreatedPopup {
    z-index: 9999;
}

.customTimePickerInput {
    // .rc-time-picker-input
    input {
        height: 45px !important;
        font-size: 14px !important;
        width: 100px !important;
    }

    .rc-time-picker-clear {
        top: 12px !important;
    }

    .rc-time-picker-clear-icon:after{
        font-size: 18px !important;
    }
} 

// .customTimePickerInput .rc-time-picker-clear{
//     top: 12px !important;
// }





