.chater {
    box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 18%);
    @media screen and (max-width: 1170px) {
        margin: 0 auto;
    }
    @media screen and (max-width: 1000px) {
        margin-right: 20px;
    }
}
.activiesBox{
    &::-webkit-scrollbar{
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: #B5B5C3;
        border-radius: 3px;
        
    }

    &.scroll-wrapper{
        max-height: 100px;
    }
    .message {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        }
    }
}