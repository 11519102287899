.andesp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twoColandes {
  white-space: unset;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.activiesBox {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3699FF;
    border-radius: 3px;

  }

  &.scroll-wrapper {
    max-height: 100px;
  }

  .message {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.cautionDiv {
  &::before {
    display: block;
    content: '';
    background-color: #1BC5BD;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 0.5rem;

    @media screen and (max-width:640px) {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }
}

.noticeDiv {
  &::before {
    display: block;
    content: '';
    background-color: #FFA800;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 0.5rem;
    @media screen and (max-width:640px) {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      margin-right: 10px;
    }
  }
}

.warningDiv {
  &::before {
    display: block;
    content: '';
    background-color: #F64E60;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 0.5rem;
    @media screen and (max-width:640px) {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      margin-right: 10px;
    }
  }
}

.annoucementRead {
  display: flex;
  position: relative;

  &::after {
    position: absolute;
    top: 16px;
    right: 20px;
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #EE436D;
  }
}