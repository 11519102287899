.fc {
  width: 100%;

  td>div>div>div:first-child {}

  table {
    width: 100% !important;
  }

  >.fc-event {
    background-color: transparent;
  }

  >div>div:nth-child(1) {
    flex-wrap: wrap;

    @media screen and (max-width: 571px) {
      >div:first-child {
        width: 100%;
      }

      >div:nth-child(2) {
        margin-top: 1rem;
      }
    }
  }
}

.eventBd,
.eventtitle {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #3699ff;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventBd {
  &::before {
    background-color: #25d1c9 !important;
  }
}

.eventHalfBd {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #25d1c9 50%, #25d1c9 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventLeftOverHalf{
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #ffa800 50%, #ffa800 50%);
    margin-right: 0.5rem;
  }
}
.eventSubstituteHalf{
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #199791 50%, #199791 50%);
    margin-right: 0.5rem;
  }
}
.eventPaternityHalf{
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #8950FC 50%, #8950FC 50%);
    margin-right: 0.5rem;
  }
}
.eventMaternityHalf{
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #00DB92 50%, #00DB92 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventHalfSickLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #EE436D 50%, #EE436D 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventHalfDay {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #3699ff 50%, #3699ff 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventHalfDayPM {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, #3699ff, #3699ff 50%, white 50%, #fff 50%, #fff 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventHalfUnpaidLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #858795 50%, #858795 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventUnpaidLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #858795;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventKOM {
  display: flex;
  align-items: center;
  white-space: normal;
  //color: #FFD058;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;

  //color: #FFD058 !important;
  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #FFD058;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventDevelopment {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #ca73e0 !important;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventProjectLaunch,
.eventProjectDesign,
.eventProjectUAT {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #DE4296;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventClientUAT {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #526AE5 !important;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventSEO {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #f64e60 !important;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventProjectLaunch {
  &::before {
    background-color: #e2a06a !important;
  }
}

.eventProjectDesign {
  &::before {
    background-color: #199791 !important;
  }
}

.eventLaunchDay {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #FFA800;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventSickLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #f64e60;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventChildLeave{
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #ABBC57;
    margin-right: 0.5rem;
  }
}
.eventLeftOver {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #ffa800;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventSubstitute {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #199791;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventMaternity {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #00DB92;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventPaternity {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #8950FC;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventContent {
  position: relative;
}

.stickerNote {
  position: absolute;
  width: auto;
  //bottom: 0;
  top: 80px;
  right: 0;
  text-align: right;
  z-index: 9;

  @media screen and (max-width:641px) {
    top: 40px;
    display: none;
  }

  // min-height: 141px;
  >span {
    background-color: rebeccapurple;
    text-align: right;
  }

  @media screen and (max-width: 481px) {
    // top: 88px;
  }

  img {
    @media screen and (max-width: 641px) {
      max-width: 1rem;
    }

    @media screen and (max-width: 481px) {
      max-width: 12px;
    }
  }

  img:hover~.stickerNoteContent {
    display: block;
  }
}

.stickerNoteContent {
  //display: none;
  position: absolute;
  background: #ffffff;
  //z-index: 9;
  //width: 200px;
  //min-height: 145px;
  overflow: visible;
  border-top: 6px solid #fff0cd;
  color: #464e5f;
  right: 20px;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
}

.customEvent {
  background-color: transparent;
  margin: 0 !important;
  position: unset !important;
  border: 0;
  padding: 0 !important;
}

.customDayCell>div>div:nth-child(2) {
  margin-bottom: 0 !important;
}

.customDayCell>div>div:nth-child(2)>div:last-child {
  // margin-bottom: 1rem !important;
}

.customEvent>div:first-child {
  position: unset !important;
}

.fc td>div>div~div {

  // height: 85% !important;
  // // min-height: 141px !important;
  // @media screen and (max-width: 1279px) {
  //   height: 78% !important;
  // }
  // @media screen and (max-width: 1140px) {
  //   height: 88% !important;
  // }
  // @media screen and (max-width: 1025px) {
  //   height: 84% !important;
  // }
  // @media screen and (max-width: 641px) {
  //   height: 84% !important;
  // }
  >div {}
}

.fc>div>div~div>div~div>div~div span {
  display: none;
}

.selectRoot:focus-visible,
.selectRoot:focus,
.selectRoot::after,
.selectRoot:before {
  border-bottom: none !important;
  transition: none !important;
  outline: none !important;
}

.selectRoot option {
  padding: 0.5rem 0.75rem;
}

.selectRoot select {
  padding: 0.5rem 0.75rem;
  height: auto !important;
  border-radius: 6px;
  font-size: 0.75rem;
  color: #858795;
  font-weight: 600;
}

.selectRoot select:focus {
  background-color: #f3f6f9 !important;
  border-radius: 6px;
}

.cusOption {
  padding: 0.5rem 0.75rem;
}

.fc-myCustomFilter-button {
  padding: 0.5rem 0.75rem;
  height: auto !important;
  border-radius: 6px;
  font-size: 0.75rem;
  color: #858795;
  font-weight: 600;
}

.fc button {
  background-color: #f3f6f9 !important;
  border: 0 !important;
  border-radius: 6px !important;
  color: #858795 !important;
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;

  &::before {
    color: #464e5f !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.customHeader {
  font-family: "Poppins", sans-serif !important;
  font-size: 0.75rem;
  font-weight: 600;
  color: #464e5f;
  padding-top: 8px;
  padding-bottom: 8px;
}

.customDayCell {
  height: 120px;
  max-height: 120px;

  @media screen and (max-width:640px) {
    height: 60px;
  }

  >div {
    height: 100% !important;
  }

  font-size: 0.75rem;
  color: #464e5f;
  font-family: "Poppins",
  sans-serif !important;
  font-weight: 400;

  a {
    color: #858795;
    padding: 0.5px !important;
    padding-right: 17px !important;
  }

  .customEvent {
    padding-right: 0 !important;
  }

  &>div:first-child>div:nth-child(2)>div:nth-child(1) {}

  &>div:nth-child(2)>div:nth-child(1) // ,

  // &>div:nth-child(2)>div:nth-child(2)>a>div 
    {
    display: none !important;
  }
}

.fc>div>div>div>div button:nth-child(1) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &:hover {
    background-color: #3699FF !important;

    >.fc-icon.fc-icon-chevron-left {
      color: white !important;
    }
  }
}

.fc>div>div>div>div button:nth-child(2) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-right: 12px;
  // background-color: white !important;

  &:hover {
    background-color: #3699FF !important;

    >.fc-icon {
      color: white !important;
    }
  }
}

.fc>div>div>div>div button:nth-child(3) {
  padding: 8px 15px !important;
}

.fc>div>div>div>div~button {
  min-width: 218px;

  @media screen and (max-width: 641px) {
    min-width: 120px;
  }

  padding: 0 !important;
  padding-right: 0.75rem !important;

  select {
    padding: 0.5rem 0.75rem !important;
    width: 100%;
    background-color: #f3f6f9 !important;

    &:focus-visible {
      outline: none !important;
    }

    option {
      font-size: 0.875rem !important;
    }
  }
}

.fc>div>div>div h2 {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  color: #464e5f !important;
  font-family: "Poppins", sans-serif !important;

  @media screen and (max-width: 1025px) {
    font-size: 0.875rem !important;
  }
}

.fc>div>div>div~div button {
  background-color: #009ef7 !important;
  padding: 8px 12px !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 0.75rem !important;
  font-weight: 600 !important;

  @media screen and (max-width: 1025px) {
    padding: 8px 12px !important;
  }
}

.eventPopup {
  margin-top: 5px !important;
  bottom: -0.35rem !important;
  font-size: 0.75rem !important;
  font-weight: normal !important;
  color: #858795 !important;
  margin-bottom: 1rem !important;

  @media screen and (max-width:1281px) {
    font-size: 0.65rem !important;
  }

  @media screen and (max-width:640px) {
    font-size: 0.5rem !important;
  }

  @media screen and (max-width:426px) {
    bottom: 0.5rem !important;
    font-size: 0.4rem !important;
  }
}

.noFilter,
.isProfile {
  >div:nth-child(1)>div:nth-child(1)>div:nth-child(2) {
    margin-left: -33%;

    @media screen and (max-width:1441px) {
      margin-left: -20%;
    }

    @media screen and (max-width: 999px) {
      margin-left: 0;
    }

    @media screen and (max-width: 361px) {
      margin-top: 3rem;
      width: 100%;
      text-align: center;
    }

    @media screen and (max-width: 321px) {
      margin-top: 2rem;
    }
  }
}

.fc .discardBtn {
  background-color: #efefef !important;
  color: #979797 !important;

  &:hover {
    background-color: #979797 !important;
    color: #fff !important;
  }
}

.fc .saveBtn {
  background-color: #009ef7 !important;
  color: #fff !important;

  &:hover {
    background-color: #efefef !important;
    color: #009ef7 !important;
  }
}

.stickerNoteContent {
  transition: all .3s;
  opacity: 0;
  height: 0;
  z-index: -1;
  position: relative;
}

// .stickerNote img:hover~.stickerNoteContent{
//   display: block;
//   opacity: 1;
//   height: auto;
//   z-index: 1;
//   position: absolute;
// }
.mobileDate {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #464E5F;
    margin-left: 10px;
  }
}

.stickerNoteMobile {
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
}