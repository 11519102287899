.otListTable {

    td[id*="project_lead"],
    td[id*="project_manager"],
    td[id*="project_id"] {
        padding: 0 !important;
    }




    th[id*="lasted"],
    td[id*="lasted"] {
        min-width: 140px !important;
        width: 140px;

        >div {
            height: 100%;
        }
    }

    th[id*="actions"],
    td[id*="actions"] {
        min-width: 120px !important;
        width: 120px;
        position: absolute !important;
        right: 0;
        left: unset !important;
        background-color: #fff;
        z-index: 1;
    }
}

.verticalLine {
    display: flex;

    &:after {
        width: 2px;
        height: 100%;
        background-color: #F5F5F5;
        display: block;
        content: '';
        margin-left: 16px;
    }
}

.otCreatedPopup {
    z-index: 9999;
}