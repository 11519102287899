.payRollTable {

    td[id*="payroll_no"],
    td[id*="process_date"],
    td[id*="employee_id"],
    td[id*="employee_name"],
    td[id*="month"],
    td[id*="basic_salary"],
    td[id*="basic_salary_sgd"],
    td[id*="total_days"],
    td[id*="office_off_days"],
    td[id*="hourly_rate"],
    td[id*="daily_rate_sgd"],
    td[id*="annual_leave"],
    td[id*="total_leaves"],
    td[id*="unpaid_leave"],
    td[id*="regular_working_days"],
    td[id*="actual_working_days"],
    td[id*="counted_working_days"],
    td[id*="counted_active_time_hours"],
    td[id*="official_90_percent_active_hours"],
    td[id*="official_80_percent_active_hours"],
    td[id*="official_70_percent_active_hours"],
    td[id*="internet_incentive"],
    td[id*="no_absent_incentive"],
    td[id*="no_lateness_incentive"],
    td[id*="work_quality_rewards_tier_1"],
    td[id*="work_quality_rewards_tier_2"],
    td[id*="other_rewards"],
    td[id*="allowance"],
    td[id*="total_incentive_reward"],
    td[id*="ot_fee"],
    // td[id*="bonus"],
    td[id*="mpf"],
    // td[id*="ssb"],
    // td[id*="income_tax"],
    // td[id*="unpaid_leave_deduction"],
    td[id*="salaryPayment"],
    td[id*="salarySGD"],
    // td[id*="final_payment_sgd"],
    td[id*="mobile"],
    td[id*="receipt_bank"],
    // td[id*="receipt_no"],
    // td[id*="bank_account_name"],
    td[id*="branchCode"],
    td[id*="ot_days"],
    td[id*="salary_payment"],
    td[id*="salary_payment_sgd"],
    td[id*="gross_pay"],
    td[id*="total_deduction"],
    td[id*="bank_account_no"],
    td[id*="is_sent"] {
        padding: 0 !important;
    }


    th[id*="lasted"],
    td[id*="lasted"] {
        min-width: 100px !important;
        width: 100px;

        >div {
            height: 100%;
        }
    }

    th[id*="actions"],
    td[id*="actions"] {
        min-width: 200px !important;
        width: 200px;
        position: absolute !important;
        right: 0;
        left: unset !important;
        background-color: #fff;
        z-index: 1;
    }
}

.verticalLine {
    display: flex;

    &:after {
        width: 2px;
        height: 100%;
        background-color: #F5F5F5;
        display: block;
        content: '';
        margin-left: 16px;
    }
}
.sendIcon{
    &:hover{
        svg path{
            fill: #1bc4bd;
        }
    }
}