.custom__dropdown_container {
    text-align: left;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;

    // width: -webkit-max-content;
    // width: -moz-max-content;
    // width: max-content;
}

.dropdown_input_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    height: 48px;
}

.custom__dropdown-container .dropdown_input {
    padding: 7px 11px;
    // display: flex;
    // display: -webkit-box;
    // display: -ms-flexbox;
    display: flex;
    // -webkit-box-align: center;
    // -ms-flex-align: center;
    align-items: center;
    // -webkit-box-pack: justify;
    // -ms-flex-pack: justify;
    justify-content: space-between;
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    gap: 7px;
}

.custom__dropdown_container .dropdown_input .dropdown_selected_value.placeholder {
    color: #858795 !important;
    font-size: 14px !important;
}

.placeholder {
    color: #858795 !important;
    font-size: 14px !important;
}

.custom__dropdown-container .dropdown_tool svg {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.custom__dropdown-container .dropdown_tool svg.translate {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.custom__dropdown_container .dropdown_menu {
    width: 100%;
    // width: -webkit-max-content;
    // width: -moz-max-content;
    // width: max-content;
    padding: 5px;
    position: absolute;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    overflow: auto;
    background-color: #fff;
    z-index: 99;
    max-height: 312px;
    min-height: 50px;
}

.custom__dropdown_container .dropdown_menu::-webkit-scrollbar {
    width: 5px;
}

.custom__dropdown_container .dropdown_menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom__dropdown_container .dropdown_menu::-webkit-scrollbar-thumb {
    background: #888;
}

.custom__dropdown_container .dropdown_menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.custom__dropdown_container .dropdown_menu.alignment__left {
    left: 0;
}

.custom__dropdown_container .dropdown_menu.alignment__right {
    right: 0;
}

.custom__dropdown_container .dropdown_item {
    padding: 7px 10px;
    cursor: pointer;
    -webkit-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
    border-radius: 6px;
    font-weight: 500;
    color: #858795 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.custom__dropdown_container .dropdown_item:hover {
    background-color: #3699FF;
    // background-color: rgba(130, 134, 139, 0.05);
    color: white !important;
}

.custom__dropdown_container .dropdown_item.selected {
    background-color: #3699FF;
    color: white;
    font-weight: 600;
}

.custom__dropdown_container .search_box {
    padding: 5px;
}

.custom__dropdown_container .search_box input {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.custom__dropdown_container .dropdown_tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px;
}

.custom__dropdown_container .dropdown_tag_item {
    background-color: #ff7300;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.custom__dropdown_container .dropdown_tag_close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 5px;
}