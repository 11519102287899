.leaveDatePicker {
    @media screen and (max-width: 360px) {
      & button {
        width: 100%;
      }
    }
  }
  
  table {
  
    th:last-child[id*="actions"],
    td:last-child[id*="actions"] {
      min-width: 120px;
      width: 120px;
      position: sticky !important;
      right: 0;
      background-color: #fff;
    }
  }
  
  // .personalLeaveTable,
  .personalLeaveBalance {
    th {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
  
      table {
  
        th:last-child[id*="actions"],
        td:last-child[id*="actions"] {
          min-width: 120px;
          width: 120px;
          position: sticky !important;
          right: 0;
          background-color: #fff;
        }
      }
    }
  }
  
  
  
    //    th {
    //      padding-top: 0.5rem !important;
    //      padding-bottom: 0.5rem !important;
    //    }
    // }
  
    .customEdit svg {
      path {
        fill: #000;
      }
    }
  
    .uploadBtn {
      background: #EFEFEF;
      font-size: 12px;
      font-family: "poppins", sans-serif;
      color: #858795;
      padding: 3px;
      border-radius: 4px;
      border: 1px solid#C2C2C2;
    }