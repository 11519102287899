.employeeTable {
    position: relative;
    td {
        font-weight: normal;
        // width: 200px;
        // min-width: 200px;
        // max-width: 249px;
        left: unset;
        >div{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            height: 100%;
            >div{
                height: 100%;
                display: flex;
                align-items: center;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                max-height: 35px !important;
            }
        }
        // >div:first-child{
        //    // min-width: 100% !important;
        //   //  max-width: 100% !important;
        // }
    }
    td[id*="race"]{
        >div>div{
            display: flex;
            align-items: center;
        }
    }
    // td[id*="gender"]{
    //     max-width: 100px !important;
    //     min-width: 100px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // td[id*="nationality"],
    // td[id*="status"]{
    //     max-width: 130px !important;
    //     min-width: 130px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // td[id*="designation"],
    // td[id*="currency_id"],   
    // td[id*="department"]{
    //     max-width: 218px !important;
    //     min-width: 218px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // th {
    //     // width: 200px;
    //     // >div{
    //     //    // max-height: 43px !important;
    //     // }
    // }
    th[id*="actions"]{
        >div{
            max-height: 45px !important;
        }
    }
    // input[type="number"],
    // input[type="email"]{
    //    // background-color: transparent;
    // }
    input[type="text"] {
        width: 100%;
       // background-color: transparent;
    }
    th[id*="lasted"],
    td[id*="lasted"] {
        min-width: 140px !important;
        width: 140px;
        > div {
            height: 100%;
        }
    }
    th[id*="actions"],
    td[id*="actions"] {
        min-width: 146px !important;
        width: 146px;
        position: sticky !important;
        right: 0;
        left: unset !important;
        background-color: #fff;
        z-index: 1;
    }
    td[id*="employeeID"],
    td[id*="age"]{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.roleTable{
    td[id*="permissions"]{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.customViewdBtn {
    &:hover {
        svg path {
            fill: #8950fc;
        }
    }
}
.customSharedBtn {
    height: auto !important;
    &:hover {
        svg path {
            fill: #1bc4bd;
        }
    }
}
.actionedBtn {
    min-width: max-content;
    // button {
    //     // min-width: 32px;
    // }
}
.customList_dropdown{
    box-shadow: 0px 0px 10px #000;
}
.customList{
    box-shadow: 0px 0px 10px #000;
    width: 100% !important;
}
.customList_dropdown>div~div,
.customList>div~div{
    &::-webkit-scrollbar{
        display: none;
    }
    &::-webkit-scrollbar-button{
        display: none;
    }
    -ms-overflow-style: none;
  scrollbar-width: none;
}
.customTextOverflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
}



.loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .equipmentTable{
    table td{
        font-weight: normal;
    }
    table td[id*=actions]{
        min-width: 170px !important;
    }
  }