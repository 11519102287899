.customButton {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    
}
.actionsButton{
    transition: all .3s;
    margin-bottom: 14px;
}
.svgRotate{
    transform: rotate(45deg);
}
.svgIcon{
    transition: all .3s;
}

.selectedDoc{
    border: 2px solid #3699FF;
}