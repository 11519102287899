
.customTimePickerInput {
    // .rc-time-picker-input
    input {
        height: 45px !important;
        font-size: 14px !important;
        width: 90px !important;
    }

    .rc-time-picker-clear {
        top: 12px !important;
    }

    .rc-time-picker-clear-icon:after{
        font-size: 18px !important;
    }
}

// .customTimePickerInput .rc-time-picker-clear{
//     top: 12px !important;
// }
