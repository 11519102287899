.content {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
  }
  
  .content.active {
    max-height: 1000px;
    min-width: 600px;
    opacity: 1;
    visibility: visible;
  }

  .selectedMember {
    background-color: #E1F0FF !important;
}

.customTimePickerInput {
  // .rc-time-picker-input
  input {
      height: 43px !important;
      font-size: 14px !important;
      width: 100px !important;
  }

  .rc-time-picker-clear {
      top: 12px !important;
  }

  .rc-time-picker-clear-icon:after{
      font-size: 18px !important;
  }
} 

// .customTimePickerInput .rc-time-picker-clear{
//     top: 12px !important;
// }
