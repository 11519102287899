td .customDropdown {
  & > div > button {
    border: 1px solid #ccdae4;
    min-height: 38px;
    color: #b6b6b6;
    border-radius: 5px;
    & > svg > path {
      fill: #b6b6b6;
    }
  }
}

.customDatePicker {
  & > div > div > button {
    @media screen and (max-width: 835px) {
      width: 130px;
    }
    @media screen and (max-width: 370px) {
      width: 140px;
    }
    @media screen and (max-width: 360px) {
      width: 100%;
    }
  }
}

.textGrayDark {
  & > div > button {
    color: #858795;
  }
}
