.uploadModalContainer {
    &::-webkit-scrollbar{
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: #B5B5C3;
        border-radius: 3px;
        
    }
}
.cusprogress {
    progress {
        border: 0;
        height: 5px;
        border-radius: 20px;
        max-width: 100%;
        width: 100%;
    }
    progress::-webkit-progress-bar {
        border: 0;
        height: 5px;
        border-radius: 20px;
        background-color:#DFDFDF;
    }
    progress::-webkit-progress-value {
        border: 0;
        height: 5px;
        border-radius: 20px;
        background:#3699FF;
    }
    progress::-moz-progress-bar {
        border: 0;
        height: 5px;
        border-radius: 20px;
        background-color:#DFDFDF;
    }
}

.uploadBtn {
    background: #EFEFEF;
    font-size: 12px;
    font-family: "poppins",sans-serif;
    color: #858795;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid#C2C2C2;
}