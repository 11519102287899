.flexContainer {
  margin-top: -16px;
  & > div {
    margin-top: 16px;
  }
}

.customWFull {
  & > div {
    width: 100%;
  }
}

.dropdownFillAvailable {
  @media screen and (max-width: 1440px) {
    & > div {
      width: 100%;
    }
  }
}
