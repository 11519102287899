.totalNumberTitle {
    transform: translate(-50%,-50%);
}
.usedDept {
    &::-webkit-scrollbar{
        width: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #B5B5C3;
          border-radius: 3px;
          
      }
    
      &.scroll-wrapper{
          max-height: 100px;
      }
}
.cusSemiDonut {
    --percentage: 0;
  --fill: #8950FC;
  width: 203px;
  height: 101px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  color: #EEE5FF;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  &:after{
    content: '';
    width: 203px;
    height: 203px;
    border:10px solid;
    border-color : #EEE5FF #EEE5FF var(--fill) var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing : border-box;
    transform: rotate( calc( 1deg * ( -45 + var(--percentage) * 1.8 ) ) );
    animation : fillAnimation 1s ease-in;
  }
}