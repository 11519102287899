.container_class {
    width: 120px !important;
}

.dropdown_class {
    width: 200px !important;
}

.input_class {
    width: 120px !important;
    height: 48px !important;
    pointer-events: none;
}

// input[type=checkbox] {
//     /* Add if not using autoprefixer */
//     -webkit-appearance: none;
//     appearance: none;
//     /* For iOS < 15 to remove gradient background */
//     // background-color: #fff;
//     accent-color: green;
//     /* Not removed via appearance */
//     margin: 0;



//     font: inherit;
//     color: currentColor;
//     width: 1.15em;
//     height: 1.15em;
//     border: 0.1em solid #7d7c7a;
//     border-radius: 0.15em;
//     transform: translateY(-0.075em);

//     display: grid;
//     place-content: center;
// }

// input[type=checkbox]::before {
//     content: "";
//     width: 0.65em;
//     height: 0.65em;
//     transform: scale(0);
//     transition: 120ms transform ease-in-out;
//     // box-shadow: inset 1em 1em red;
//     accent-color: green;

//     // transform-origin: bottom left;
//     // clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
// }

// input[type=checkbox]:checked::before {
//     transform: scale(1);
//     // background-color: rgba(54, 153, 255, 0.75)
// }

.createProjectData {
    background-color: #3699ffbf
}