.eventBd,
.eventtitle {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #3699ff;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventBd {
  &::before {
    background-color: #25d1c9 !important;
  }
}

.eventHalfBd {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #25d1c9 50%, #25d1c9 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventLeftOverHalf {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #ffa800 50%, #ffa800 50%);
    margin-right: 0.5rem;
  }
}

.eventSubstituteHalf {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #199791 50%, #199791 50%);
    margin-right: 0.5rem;
  }
}

.eventPaternityHalf {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #8950FC 50%, #8950FC 50%);
    margin-right: 0.5rem;
  }
}

.eventMaternityHalf {
  display: flex;
  align-items: center;
  color: #494b69;
}

.eventLeftOverHalf {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #ffa800 50%, #ffa800 50%);
    margin-right: 0.5rem;
  }
}

.eventSubstituteHalf {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #199791 50%, #199791 50%);
    margin-right: 0.5rem;
  }
}

.eventPaternityHalf {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #8950FC 50%, #8950FC 50%);
    margin-right: 0.5rem;
  }
}

.eventMaternityHalf {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #00DB92 50%, #00DB92 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}


.eventHalfSickLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #EE436D 50%, #EE436D 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventHalfDay {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #3699ff 50%, #3699ff 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventHalfDayPM {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, #3699ff, #3699ff 50%, white 50%, #fff 50%, #fff 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventHalfUnpaidLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(-90deg, white, white 50%, white 50%, #858795 50%, #858795 50%);
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventUnpaidLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #858795;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventKOM {
  display: flex;
  align-items: center;
  white-space: normal;
  //color: #FFD058;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;

  //color: #FFD058 !important;
  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #FFD058;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventDevelopment {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #ca73e0 !important;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventProjectLaunch,
.eventProjectDesign,
.eventProjectUAT {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #DE4296;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventClientUAT {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #526AE5 !important;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventSEO {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #f64e60 !important;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventProjectLaunch {
  &::before {
    background-color: #e2a06a !important;
  }
}

.eventProjectDesign {
  &::before {
    background-color: #199791 !important;
  }
}

.eventLaunchDay {
  display: flex;
  align-items: center;
  white-space: normal;
  color: #494b69;
  font-size: 0.75rem !important;

  @media screen and (max-width: 1281px) {
    font-size: 0.65rem !important;
  }

  font-weight: 600 !important;
  color: #464e5f !important;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 2px;
    background-color: #FFA800;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventSickLeave {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #f64e60;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventChildLeave{
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #ABBC57;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}
.eventLeftOver {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #ffa800;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventSubstitute {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #199791;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventMaternity {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #00DB92;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}

.eventPaternity {
  display: flex;
  align-items: center;
  color: #494b69;

  &::before {
    width: 10px;
    height: 10px;
    content: "";
    margin-top: 5px;
    margin-top: 5px;
    min-width: 10px;
    display: block;
    border-radius: 50%;
    background-color: #8950FC;
    margin-right: 0.5rem;

    // @media screen and (max-width:640px) {
    //   width: 7px;
    //   height: 7px;
    // }
  }
}